
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getComponent } from '@/utils/helpers';

import TasqDetailsMixin from '@/components/tasqsCommon/TasqDetailsMixin';
import tasqsListModule from '@/store/modules/tasqsListModule';
import GenericMixin from '@/lib/mixins/GenericMixin';

// @ts-ignore
@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqDetails: () => getComponent('tasqs/TasqDetailV2'),
    WorkTicketDetail: () => getComponent('workTickets/WorkTicketDetail'),
    AddWorkTicket: () => getComponent('workTickets/AddWorkTicket'),
    ActionBubble: () => getComponent('tasqs/ActionBubble'),

  },
})
export default class TasqsDesktop extends mixins(TasqDetailsMixin, GenericMixin) {
  showAddWorkTicket = false;

  get activeWorkTicket() {
    return (
      tasqsListModule.activeWorkTicket
      || (this.$route.params.id && this.$route.query.type === 'workticket')
    );
  }

  get gridStyles() {
    if (this.isVerticalListUnPinned && this.isVerticalListHovering) {
      return 'relative grid grid-rows-1';
    }
    if (this.isVerticalListUnPinned && !this.isVerticalListHovering) {
      return 'overlay-vertical-miniview';
    }
    return 'overlay-vertical-unpin';
  }

  get activeTasqExist() {
    if (this.isEditing && !this.isBatchResponding) {
      return false;
    }
    return (
      tasqsListModule.activeTasq
      || (this.$route.params.id
        && this.$route.query.type !== 'workticket'
        && (this.$route.query.view === 'tasq' || this.$route.query.view === 'wellview'))
    );
  }

  async created() {
    tasqsListModule.setIsMobileView(false);
    await this.loadProducingWellData();
    await this.initializeTasqDetails();
  }
}
